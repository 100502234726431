<template>
    <div class="container">
        <h1>A bientot Notification</h1>
        <div class="row gutters-sm">
            <div class="col-md-8">
                <div class="card"></div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from 'vue';
    import { useApiStore } from '@/store/store-index';
    import '@vuepic/vue-datepicker/dist/main.css';

    export default defineComponent({
        setup() {
            const apiStore = useApiStore();
            return {
                apiStore,
            };
        },
        data() {
            return {
                selectedPos: [],
                alias: {},
                password: null,
                pdfsrc: '',
            };
        },
        computed: {},
        methods: {},
    });
</script>

<style></style>
