<template>
    <div class="pdv-detail">
        <div class="header-container">
            <icon-button icon-type="chevron-left" variant="white" @click="navigateToPos" v-if="apiStore.posDetails.length > 1" />
            <span class="fsb-typo-heading-1">{{ pos.point_name }}</span> <span class="fsb-typo-para-regular">{{ pos.point_code }}</span>
        </div>
        <div class="card-container">
            <GeneralDetail :pos="pos" />
            <RepiquageDetail :pos="pos" />
            <MandateDetail :mandate="pos" />
            <base-card class="card abonnement-actif">
                <div class="subscription-header">
                <check-field :status="true" />
                <h3 class="fsb-typo-heading-5 subscription">
                    {{ pos?.subscription == 'Ajouter' ? t('inactiveSubscription') : t('activeSubscription') }}
                </h3>
                </div>
            </base-card>
            <InvoiceDetail :payment-info="pos.payment_info" />
            <InvoiceContact :payment-info="pos.payment_info" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useApiStore } from '@/store/store-index';
    import InvoiceDetail from './invoiceDetail.vue';
    import GeneralDetail from './generalDetail.vue';
    import MandateDetail from './mandateDetail.vue';
    import RepiquageDetail from './repiquageDetail.vue';
    import InvoiceContact from './invoiceContact.vue';
    import { useI18n } from 'vue-i18n';

    const apiStore = useApiStore();
    const router = useRouter();
    const route = useRoute();
    const navigateToPos = () => {
        router.push('/pos');
    };
    const pointCode = ref(route.params.pointCode as string);
    const payment = apiStore.posDetails;
    const { t } = useI18n({ useScope: 'global' });

    const pos = computed(() => {
        return payment.find((item) => item.point_code == pointCode.value);
    });
</script>

<style lang="scss" scoped>
    .pdv-detail {
        .header-container {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .card-container {
            column-count: 2;
            column-gap: 36px;
            margin-top: 24px;
            width: 100%;
        }

        .subscription-header {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .card {
            background: #ffffff;
            border-radius: 16px;
            padding: 24px;
            text-align: left;
            display: inline-block;
            width: 100%;
            box-sizing: border-box;
            margin-bottom: 36px;
            break-inside: avoid-column;
            page-break-inside: avoid;
            box-shadow: none !important;
            border: none !important;
        }
        .card:last-child {
            margin-bottom: 0;
        }
    }
    .subscription {
        color: #707070
    }
    .fsb-typo-heading-3 {
        text-align: left;
        margin-left: 0;
        padding-left: 0;
    }
</style>
