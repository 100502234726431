<template>
    <Datepicker :enableTimePicker="false" range inline autoApply @update:modelValue="updateDate" :multiCalendars="2" :maxDate="maxDate"/>
</template>

<script lang="ts" setup>
    import { configureCompat } from 'vue';
    import Datepicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css';

    configureCompat({ MODE: 2 });

    const emit = defineEmits(['update-date']);
    const updateDate = function (date: any) {
        emit('update-date', date);
    };
    const maxDate = new Date();
</script>

<style>
    :root {
        --dp-primary-color: black !important;
    }

    .my-datepicker .dp__instance_calendar {
        margin-right: 50px;
    }
    .dp__menu .dp__relative .dp__theme_light {
        background-color: #fff !important;
        border: none !important;
        color: #333 !important;
    }

    .dp__theme_light {
        --dp-border-color: none !important;
        --dp-menu-border-color: none !important;
        --dp-border-color-hover: none !important;
    }
    .dp__range_end,
    .dp__range_start,
    .dp__active_date {
        --dp-primary-color: black;
        background-color: var(--dp-primary-color);
    }
</style>
