// stores/nav.js

import { defineStore } from 'pinia';

export const useFilterStore = defineStore({
    id: 'filter',
    state: () => ({
        selectedFilterItem: 'all',
    }),
    actions: {
        selectFilterItem(item: string) {
            if (this.selectedFilterItem === item) {
                this.selectedFilterItem = '';
            } else {
                this.selectedFilterItem = item;
            }
        },
    },
});
