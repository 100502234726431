<template>
    <div class="h-100 d-flex justify-content-center align-items-center"></div>
</template>

<script lang="ts">
    import { defineComponent, ref, onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { useApiStore } from '@/store/store-index';

    export default defineComponent({
        setup() {
            const apiStore = useApiStore();
            const router = useRouter();
            const selectedItem = ref('');

            onMounted(() => {
                apiStore.init();
                router.push('/profile');
            });

            return {
                selectedItem,
                apiStore,
            };
        },
    });
</script>
