import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import ProfileView from '../views/profile/profileHome.vue';
import LoginView from '../views/login/loginIndex.vue';
import PointsDeVente from '../views/pos/pdvHome.vue';
import DetailPointsDeVente from '../views/pos/pdvDetail.vue';
import NotificationVue from '../views/notification/notificationHome.vue';
import InvoicesVue from '../views/invoices/InvoicesHome.vue';
import AssociesVue from '../views/associes/associesHome.vue';
import ModifDetailsUser from '../views/password/passwordHome.vue';
import LoadingView from '../views/loading/loadingIndex.vue';

import { useApiStore, useAuthStore } from '@/store/store-index';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'start',
        component: ProfileView,
    },
    {
        path: '/profile',
        name: 'profile',
        component: ProfileView,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
    },
    {
        path: '/pos',
        name: 'pos',
        component: PointsDeVente,
    },
    {
        path: '/pos/:pointCode',
        name: 'posDetail',
        component: DetailPointsDeVente,
    },
    {
        path: '/notification',
        name: 'notification',
        component: NotificationVue,
    },
    {
        path: '/invoices',
        name: 'invoices',
        component: InvoicesVue,
    },
    {
        path: '/associes',
        name: 'associes',
        component: AssociesVue,
    },
    {
        path: '/password',
        name: 'password',
        component: ModifDetailsUser,
    },
    {
        path: '/loading',
        name: 'loading',
        component: LoadingView,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const isIframe = window !== window.parent;
    const apiStore = useApiStore();

    if (to.path === '/pos' && apiStore.posDetails.length === 1) {
        next({ name: 'posDetail', params: { pointCode: apiStore.posDetails[0].point_code } });
    }
    if (to.name === 'start') {
        return next({ name: 'share-token' });
    }
    if (process.env.NODE_ENV === 'development' && !isIframe) {
        const authStore = useAuthStore();
        const token = authStore.getToken();

        if (to.name !== 'login') {
            if (!token || !authStore.isTokenValid(14400000)) {
                return next({ name: 'login' });
            } else if (!to.name) {
                return next('/profile');
            } else {
                return next();
            }
        }
    }
    if (to.name === 'login') {
        const authStore = useAuthStore();
        const apiStore = useApiStore();
        authStore.isLogged = false;
        apiStore.$reset();
    }
    next();
});

export default router;
