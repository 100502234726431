<template>
    <div>
        <div class="dropdown-container">
            <fsb-dropdown class="pdv" :linkedInput="t('pos')" :list="dropdownItems" v-model="selectedItem" :close-dropdown-on-click-outside="true" checkbox />
            <fsb-dropdown
                class="period"
                :linkedInput="t('periods')"
                :list="filter_periods"
                :select-one="true"
                :label-change-on-item-click="true"
                :close-dropdown-on-item-click="true"
                v-model="periodSelected"
            />
            <ui-button class="custom-period" v-if="showCustomDate" :label="customPeriodLabel" variant="white" @click="toggleDatePicker" />
            <base-modal
                title="Définissez une date"
                variant="tertiary"
                hideHeader
                :modal-show="isModalOpen"
                :id="modalId"
                size="lg"
                hide-header-close
                tertiary-label="Fermer"
                :on-tertiary-button-click="close"
                primary-label="Valider"
                :on-primary-button-click="confirmDatePicker"
                :show-secondary-button="false"
            >
                <period-picker @update-date="handleDateSelection" />
            </base-modal>
            <group-selector class="group-select" :label-list="typeLIst()" :black="true" @valueClicked="updateFilter" />
        </div>
        <dynamic-table
            :key="tableKey"
            :fields="formattedFields"
            :items="filteredInvoices"
            unique-key="code"
            with-actions-column
            :per-page="tableRowsPerPage[0].value"
            :empty-text="t('noInvoice')"
            :empty-filtered-text="t('noInvoice')"
            @checkboxClicked="updateSelectedInvoices"
            :pagination-label="t('invoices')"
        >
            <template #actions="item">
                <icon-button icon-type="view" variant="secondary" @click="() => emit('openInvoice', item)" />
                <icon-button icon-type="download" variant="secondary" @click="() => emit('downloadInvoice', item)" />
            </template>
        </dynamic-table>
    </div>
</template>

<script setup>
    import { computed, ref, defineEmits, defineProps, watch } from 'vue';
    import { useApiStore } from '@/store/store-index';
    import { useI18n } from 'vue-i18n';
    import moment from 'moment';
    import PeriodPicker from './periodPicker.vue';

    const emit = defineEmits(['updateSelectedInvoices', 'openInvoice', 'downloadInvoice']);
    const props = defineProps({
        selectedInvoices: Array,
    });

    const { t } = useI18n({ useScope: 'global' });
    const apiStore = useApiStore();

    const selectedItem = ref([]);
    const periodSelected = ref('');
    const tableRowsPerPage = ref([{ label: '25 ' + t('lines'), value: 25 }]);
    const selectedDateRange = ref(null);
    const tableKey = ref(0);
    const campaign_types = ['Toutes', 'Abonnements', 'Campagnes'];
    const selectedFilter = ref('Toutes');
    const showCustomDate = ref(false);
    const filter_periods = [t('thisMonth'), t('lastTrimester'), t('lastSemester'), t('customPeriod'), t('allInvoices')];
    const isModalOpen = ref(false);

    const dropdownItems = computed(() => {
        const pdv = apiStore.posDetails ?? [];
        return Array.from(new Map(pdv.map((item) => [item.point_code, item])).values()).map((item) => ({
            label: ` ${item.point_name}`,
            value: item.point_code,
        }));
    });

    const updateSelectedInvoices = (selectedOptions) => {
        emit('updateSelectedInvoices', { selectedOptions: selectedOptions.selectedOptions });
    };

    const customPeriodLabel = computed(() => {
        if (showCustomDate.value && selectedDateRange.value && selectedDateRange.value.length === 2) {
            const [startDate, endDate] = selectedDateRange.value;
            return `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`;
        }
        return t('selectCustomPeriod');
    });

    const formattedFields = computed(() => [
        { key: 'name', label: t('Facture'), sortable: true },
        { key: 'point_code', label: t('Code'), sortable: true },
        { key: 'point_name', label: t('Point de Vente'), sortable: true },
        { key: 'type', label: t('Type'), sortable: true },
        { key: 'date', label: t('Date'), sortable: true },
        { key: 'price', label: t('Amount'), formatPrice: true, sortable: true },
    ]);

    const typeLIst = () => {
        return campaign_types.map((label) => ({
            label: label,
            value: label,
        }));
    };

    const updateFilter = (item) => {
        selectedFilter.value = item.value;
    };

    watch(periodSelected, (newValue) => {
        showCustomDate.value = newValue[0] === t('customPeriod');
        if (!showCustomDate.value) {
            selectedDateRange.value = null;
        }
    });

    const toggleDatePicker = () => {
        isModalOpen.value = !isModalOpen.value;
    };

    const handleDateSelection = (dateRange) => {
        selectedDateRange.value = dateRange;
    };

    const filteredInvoices = computed(() => {
        let invoices = apiStore.invoices;

        if (selectedItem.value.length > 0) {
            const selectedValues = selectedItem.value.map((item) => item.value);
            invoices = invoices.filter((invoice) => selectedValues.includes(invoice.point_code));
        }

        if (selectedDateRange.value && periodSelected.value.includes(t('allInvoices'))) {
            invoices = invoices;
        }
        if (selectedDateRange.value && periodSelected.value.includes(t('customPeriod'))) {
            const [startDate, endDate] = selectedDateRange.value.map((date) => moment(date));
            invoices = invoices.filter((invoice) => {
                const invoiceDate = moment(invoice.date, 'DD/MM/YYYY');
                return invoiceDate.isSameOrAfter(startDate) && invoiceDate.isSameOrBefore(endDate);
            });
        } else if (periodSelected.value.length > 0 && !periodSelected.value.includes(t('customPeriod'))) {
            const { startDate, endDate } = getDateRange(periodSelected.value);
            invoices = invoices.filter((invoice) => {
                const invoiceDate = moment(invoice.date, 'DD/MM/YYYY');
                const isBetween = invoiceDate.isBetween(startDate, endDate, undefined, '[]');
                return isBetween;
            });
        }

        if (selectedFilter.value === 'Toutes') {
            return invoices.map((invoice) => ({
                ...invoice,
                price: invoice.value,
            }));
        } else {
            const filtered = invoices.filter((invoice) => invoice.type === selectedFilter.value);
            return filtered.map((invoice) => ({
                ...invoice,
                price: invoice.value,
            }));
        }
    });

    // Watch for changes in filteredInvoices to see if the computed property reacts to changes
    watch(filteredInvoices, (newVal) => {
        tableKey.value++;
    });

    const confirmDatePicker = () => {
        toggleDatePicker();
    };

    const close = () => {
        selectedDateRange.value = '';
        toggleDatePicker();
    };

    const getDateRange = (period) => {
        let startDate, endDate;
        switch (period[0]) {
            case t('thisMonth'):
                startDate = moment().startOf('month');
                endDate = moment().endOf('month');
                break;
            case t('lastTrimester'):
                startDate = moment().subtract(3, 'months').startOf('day');
                endDate = moment().endOf('day');
                break;
            case t('lastSemester'):
                startDate = moment().subtract(6, 'months').startOf('day');
                endDate = moment().endOf('day');
                break;
            default:
                startDate = moment().startOf('year');
                endDate = moment().endOf('year');
                break;
        }
        return { startDate, endDate };
    };
</script>
<style lang="scss" scoped>
    .modal-body .top-round-modal-body {
        all: unset;
        background-color: white !important;
    }
    body .modal-content .modal-body div {
        background-color: white;
    }
    body .modal-content .modal-body div {
        background-color: white !important;
    }
    .custom-period {
        border: 1px solid var(--grayscale-gray-regular) !important;
        border-radius: 8px !important;
        color: grey !important;
    }

    .dropdown-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 24px;

        .pdv,
        .period,
        .custom-period {
            min-width: 0;
            margin-right: 5px;
            font-size: 12px;
        }
    }

    .fsb-typo-para-regular {
        color: #707070;
    }
    .group-select {
        margin-left: auto;
    }

    @media (max-width: 768px) {
        .dropdown-container {
            flex-direction: column;
        }
    }
</style>
