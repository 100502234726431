<template>
    <base-card class="card repiquage-detail">
        <div class="card-title">
            <h2 class="fsb-typo-heading-2">{{ t('replication') }}</h2>
            <!-- Commented out because the edit option is not functional at the moment -->
            <!-- <icon-button icon-type="edit" variant="secondary" /> -->
        </div>
        <div class="mandate-details">
            <div class="detail-item">
                <p class="fsb-typo-para-medium">{{ repiquage }}</p>
            </div>
        </div>
    </base-card>
</template>

<script setup lang="ts">
    import { defineProps, computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps({
        pos: Object,
    });

    const repiquage = computed(() => {
        return props?.pos?.name_repiquage;
    });
</script>

<style lang="scss" scoped>
    .card-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .mandate-details {
        width: 100%;
        .detail-item {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            width: 100%;

            .detail-value {
                color: #333;
                font-weight: bold;
                text-align: right;
                width: 60%;
            }
        }
    }
</style>
