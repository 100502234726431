<template>
    <div class="users-list">
        <div class="user-main__header">
            <h1 class="fsb-typo-heading-1">{{ t('Points de Vente') }}</h1>
            <dynamic-table
                with-actions-column
                :fields="formattedFields"
                :items="pos"
                :unique-key="'point_code'"
                @item-clicked="handleRowClick"
                :enableCheckbox="false"
                :per-page="tableRowsPerPage[0].value"
            >
                <template #actions="item">
                    <icon-button icon-type="chevron-right" variant="white" @click="() => iconClicked(item)" />
                </template>
            </dynamic-table>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { computed, ref, onMounted } from 'vue';
    import { useApiStore } from '@/store/store-index';
    import { useI18n } from 'vue-i18n';
    import { useRouter } from 'vue-router';

    const { t } = useI18n({ useScope: 'global' });

    const tableLinesLabel = ref([
        { label: '25 ' + t('lines'), value: 25 },
        { label: '50 ' + t('lines'), value: 50 },
        { label: '75 ' + t('lines'), value: 75 },
        { label: '100 ' + t('lines'), value: 100 },
    ]);
    const apiStore = useApiStore();
    const router = useRouter();
    const tableRowsPerPage = ref([tableLinesLabel.value[0]]);

    const iconClicked = (item: any) => {
        router.push({ name: 'posDetail', params: { pointCode: item.item.point_code } });
    };

    const handleRowClick = (index: number, item: any) => {
        router.push({ name: 'posDetail', params: { pointCode: item.point_code } });
    };

    const pos = computed(() => {
        return apiStore.posDetails;
    });
    onMounted(() => {
        apiStore.fetchMandateStatus(); 
    });
    const formattedFields = computed(() => [
        { key: 'point_code', label: t('Code'), sortable: true },
        { key: 'point_name', label: t('Point de Vente'), sortable: true },
        {
            key: 'mandate.mandate_statusText',
            label: t('Mandat Publicitaire'),
            sortable: true,
            getContent: (item: { mandate: { mandate_status: any; validity: any } }) => {
                if (item?.mandate?.mandate_status) {
                    return {
                        name: 'mandate-tag',
                        props: {
                            mandateStatus: item?.mandate?.mandate_status,
                            validity: item?.mandate?.validity,
                        },
                    };
                } else {
                    const label = t('notCreated');
                    return {
                        name: 'mandate-tag',
                        props: {
                            label: label,
                            variant: 'gray',
                        },
                    };
                }
            },
        },
       //Commented out because the add comptable option is not functional at the moment
        // {
        //     key: 'comptable.email',
        //     label: 'Comptable',
        //     sortable: true,
        //     getContent: (item: { comptable: { email: any } }) => {
        //         if (!item?.comptable?.email) {
        //             return {
        //                 name: 'ui-button',
        //                 props: {
        //                     label: 'Ajouter',
        //                     variant: 'secondary',
        //                     small: true,
        //                     rightIcon: 'add',
        //                 },
        //             };
        //         }
        //         return null;
        //     },
        // },
        {
            key: 'subscription',
            label: t('Abonnement'),
            sortable: true,
            getContent: (item: { subscription: any }) => {
                    return {
                        name: 'ui-button',
                        props: {
                            label: "Activer l'abonnement",
                            variant: 'secondary',
                            small: true,
                            disabled: true
                        },
                    };
            },
        },
    ]);
</script>

<style lang="scss">
    table th,
    table td {
        vertical-align: baseline !important;
    }

    .users-list {
        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            &__first,
            &__second {
                display: flex;
                gap: 16px;
            }
        }

        .fsb-typo-heading-1 {
            padding-bottom: 24px;
        }
        .linesFilter {
            > div {
                width: 100px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    overflow: hidden !important;
                }
            }
        }

        .groupFilter {
            > div {
                width: 200px !important;
                cursor: pointer;
                &.fsb-dropdown {
                    overflow: hidden !important;
                }
            }
        }
    }
</style>
