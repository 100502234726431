<template>
    <base-modal
        :key="modalKey"
        :modal-show="modalShow"
        :id="modalId"
        title="Facture"
        hide-header-close
        :with-max-height="false"
        :tertiary-label="'Fermer'"
        :on-tertiary-button-click="close"
        :show-secondary-button="false"
        :primary-label="t('download')"
        :on-primary-button-click="() => downloadInvoice(currentItem)"
        @close-modal="close"
    >
        <embed :src="pdfsrc" type="application/pdf" />
    </base-modal>
</template>

<script setup>
    import { ref } from 'vue';
    import { defineProps, defineEmits } from 'vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({ useScope: 'global' });

    const props = defineProps({
        modalShow: Boolean,
        pdfsrc: String,
        modalKey: Number,
        currentItem: Object,
    });

    const emit = defineEmits(['close', 'downloadInvoice']);

    const modalId = ref('');

    const close = () => {
        emit('close');
    };

    const downloadInvoice = (item) => {
        emit('downloadInvoice', item);
    };
</script>
