<template>
    <base-card class="card invoice-contact" :class="$attrs.class">
        <div class="card-title">
            <h4 class="fsb-typo-heading-2">{{ t('contactInvoice') }}</h4>
        </div>
        <div class="facturation-details">
            <div class="detail-item">
                <h5 class="detail-label fsb-typo-heading-5">{{ t('debitNotification') }}</h5>
            </div>
            <div class="contact-info">
                <span class="fsb-typo-btn-secondary">{{ contactDetail }}</span>
                <span class="fsb-typo-para-regular">{{ contactLabel }}</span>
            </div>
            <div class="detail-item">
                <h5 class="detail-label fsb-typo-heading-5">{{ t('accountingContact') }}</h5>
                <!-- <div class="button-container">
                    <ui-button label="Ajouter" variant="secondary" right-icon="add" small="true" />
                </div> -->
            </div>
        </div>
    </base-card>
</template>

<script lang="ts" setup>
    import { computed } from 'vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n();
    interface PaymentInfo {
        email: string;
        name: string;
        point_text: string;
        company_name: string;
        address: string;
        subscription: {
            amount: number;
            status: string;
        };
        accountingDept?: object;
    }
    const props = defineProps({
        paymentInfo: {
            type: Object as PaymentInfo,
            required: true,
        },
    });

    const contactLabel = computed(() => {
        return props.paymentInfo.accountingDept ? 'Contact comptable' : "Signataire de l'abonnement";
    });

    const contactDetail = computed(() => {
        if (props.paymentInfo.accountingDept) {
            const dept = props.paymentInfo.accountingDept;
            return ` ${dept.email}`;
        } else {
            return props.paymentInfo.email;
        }
    });
</script>

<style lang="scss" scoped>
    .invoice-contact {
        .card-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
        }
        .button-container {
            margin-top: 8px;
        }
        .facturation-details {
            display: flex;
            flex-direction: column;

            .contact-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
            }

            .detail-item {
                margin-bottom: 10px;

                .detail-label {
                    color: #333;
                    margin-bottom: 4px;
                }
            }
            .fsb-typo-btn-secondary {
                text-align: left;
                margin-bottom: 10px;
            }

            .fsb-typo-para-regular {
                text-align: right;
                margin-bottom: 10px;
                color: #707070;
            }
        }
    }
</style>
