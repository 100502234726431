<template>
    <div class="header-section">
        <h1 class="fsb-typo-heading-1">{{ t('invoices') }}</h1>
        <p class="fsb-typo-para-regular">{{ t('downloadInvoices') }}</p>
    </div>
</template>

<script setup>
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({ useScope: 'global' });
</script>

<style scoped>
    .fsb-typo-heading-1 {
        padding-bottom: 8px;
    }
    .fsb-typo-para-regular {
        color: #707070;
    }
</style>
