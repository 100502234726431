import { createApp, markRaw } from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue, PaginationPlugin } from 'bootstrap-vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import { axiosInstance } from '@/axiosInstance';
import { createI18n } from 'vue-i18n';
import { LmpPlugin } from 'vue3-lmp-plugin';

// GLOBAL COMPONENTS
import fsb from '@adcleek/front-storybook';


// library.add(fas, far)
import { useAuthStore, useTranslationStore } from './store/store-index';
import { Router } from 'vue-router';

import Toast, { PluginOptions } from 'vue-toastification';
// Import the CSS or use your own!
import 'vue-toastification/dist/index.css';

declare module 'pinia' {
    export interface PiniaCustomProperties {
        router: Router;
    }
}

const app = createApp(App);
const pinia = createPinia();
// message()
pinia.use(({ store }) => {
    store.router = markRaw(router);
});

app.use(pinia);
app.use(BootstrapVue).use(PaginationPlugin);



const options: PluginOptions = {
    // You can set your default options here
};

const authStore = useAuthStore();
const translationStore = useTranslationStore();
authStore.init();
app.use(Toast, options);

const navLanguage: string = window.navigator.language.split('-')[0];

axios
    .get(process.env.VUE_APP_TRANSLATIONS_URL + '?lang=' + navLanguage + '&index.count=100000000')
    .then((res: any) => {
        const translationsRaw = res.data;
        const translations = {};
        const lang = ['fr', 'en', 'de', 'es', 'it'];

        lang.forEach((language) => {
            (translations as any)[language] = {};
        });

        translationsRaw.forEach((translation: any) => {
            (translations as any)[translation.lang][translation.name] = translation.value;
        });

        translationStore.navLanguage = navLanguage;
        translationStore.translations = translations;

        const i18n = createI18n({
            locale: navLanguage,
            legacy: false,
            allowComposition: true,
            messages: translations,
        });
        app.use(i18n);
        app.use(LmpPlugin, { axiosInstance, router });
        app.use(router);
        fsb.install(app);

        app.mount('#app');
    })
    .catch((e) => {
        console.error(e);
    }) as Promise<any>;

(window as any).app = app;
