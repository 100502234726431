<template>
    <base-card class="card invoice-detail" :class="$attrs.class">
        <div class="card-title">
            <h2 class="fsb-typo-heading-2">{{ t('billingInformations') }}</h2>
            <!-- Commented out because the edit option is not functional at the moment -->
            <!-- <icon-button icon-type="edit" variant="secondary" /> -->
        </div>
        <div class="facturation-details">
            <div class="detail-item">
                <span class="detail-label fsb-typo-para-regular"> {{ t('subscriber') }} </span>
                <span class="fsb-typo-btn-principal">{{ paymentInfo.name }}</span>
            </div>
            <div class="detail-item">
                <span class="detail-label fsb-typo-para-regular">{{ t('email') }}</span>
                <span class="fsb-typo-btn-principal">{{ paymentInfo.email }}</span>
            </div>
            <div class="detail-item">
                <span class="detail-label fsb-typo-para-regular">{{ t('socialReason') }}</span>
                <span class="fsb-typo-btn-principal">{{ paymentInfo.company_name }}</span>
            </div>
            <div class="detail-item">
                <span class="detail-label fsb-typo-para-regular">{{ t('siret') }}</span>
                <span class="fsb-typo-btn-principal">{{ paymentInfo.siret ? paymentInfo.siret : '-' }}</span>
            </div>
            <div class="detail-item">
                <span class="detail-label fsb-typo-para-regular">{{ t('billingAddress') }} </span>
                <span class="fsb-typo-btn-principal">{{ paymentInfo.address }}</span>
            </div>
        </div>
    </base-card>
</template>

<script lang="ts" setup>
    import { PropType } from 'vue';
    import { useI18n } from 'vue-i18n';
    const { t } = useI18n({ useScope: 'global' });

    interface InvoiceInfo {
        name: string;
        email: string;
        company_name: string;
        siret: string;
        address: string;
        billing_address: string;
    }

    const props = defineProps({
        paymentInfo: {
            type: Object as PropType<InvoiceInfo>,
            required: true,
        },
    });
</script>

<style lang="scss" scoped>
    .fsb-typo-btn-principal {
        color: var(--grayscale-gray-dark);
    }
    .invoice-detail {
        .card-title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
        }

        .facturation-details {
            width: 100%;
            .detail-item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                width: 100%;

                .detail-label {
                    color: #333;
                    width: 40%;
                }

                .detail-value {
                    color: #333;
                    font-weight: bold;
                    text-align: right;
                    width: 60%;
                }
            }
        }

        .edit-icon {
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 20px;
        }
    }
</style>
