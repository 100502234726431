<template>
    <div class="h-100 d-flex justify-content-center align-items-center">
        <b-card>
            <b-form @submit.prevent="onSubmit">
                <b-form-group :label="$t('email')" label-for="input-email">
                    <b-form-input id="input-email" v-model="form.login" type="email" placeholder="Enter email" required />
                </b-form-group>
                <b-form-group :label="$t('password')" label-for="input-password">
                    <b-form-input id="input-password" v-model="form.password" type="password" placeholder="Enter password" required />
                </b-form-group>

                <b-button variant="primary" @click="onSubmit">
                    {{ $t('login') }}
                </b-button>
            </b-form>
        </b-card>
    </div>
</template>

<script lang="ts">
    import { defineComponent, ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { useAuthStore } from '@/store/store-index';

    export default defineComponent({
        setup() {
            const authStore = useAuthStore();
            const router = useRouter();

            const form = ref({
                login: '',
                password: '',
            });

            const onSubmit = () => {
                authStore.login(form.value);
                router.push('profile');
            };

            return {
                form,
                onSubmit,
            };
        },
    });
</script>
