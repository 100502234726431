import { defineStore } from 'pinia';

export const useHomeStore = defineStore('useHomeStore', {
    state: () => ({
        invoicesFilter: {} as any,
        error: null,
        data: null,
    }),
    actions: {},
});
